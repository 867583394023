import React, { useEffect, useState } from 'react';
import { Divider, notification, Table, Drawer, Card, Button, Spin, Descriptions, Radio, message } from 'antd';
import { ReportStore } from '../../stores';
import { ErrorRender } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { QualityKpisReportFilterForm } from '../../components/SalesOrder';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const QualityKpisReport = observer((props: any) => {
    const searchParams = useQuery();
    const [drawer, setDrawer] = useState(false);
    const [dataDrawer, setDataDrawer] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<any>();

    const getReports = async (params = {}) => {
        setLoading(true);
        await ReportStore.reasonsQualityKpis(params)
            .then(response => {
                setRecords(response);
            })
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getReports(searchParams);
    }, [props.location.search]);

    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'sales_order',
            key: 'sales_order',
            render: (_: string, record: any) => {
                return <Link to={`/sales-order/${record.sales_order.id}/`}>{record.sales_order.name}</Link>;
            },
        },
        {
            title: 'FIXING Required',
            dataIndex: 'rma_required',
            key: 'rma_required',
            render: (_: string, record: any) => {
                return <span>{record.rma_required ? 'Yes' : 'No'}</span>;
            },
        },
        {
            title: 'REDO Required',
            dataIndex: 'redo_required',
            key: 'redo_required',
            render: (_: string, record: any) => {
                return <span>{record.redo_required ? 'Yes' : 'No'}</span>;
            },
        },
        {
            title: 'Contact Information',
            dataIndex: 'reporting_contact_information',
            key: 'reporting_contact_information',
        },
        {
            title: 'Issue Description',
            dataIndex: 'issues',
            key: 'issues',
        },
        {
            title: 'Fault',
            dataIndex: 'fault',
            key: 'fault',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: string, record: any) => {
                return (
                    <a
                        key={'open-drawer'}
                        onClick={() => {
                            console.log(record);
                            setDataDrawer(record);
                            setDrawer(true);
                        }}
                    >
                        Show Details
                    </a>
                );
            },
        },
    ];

    return (
        <>
            <QualityKpisReportFilterForm />

            <Divider>
                <div className={'title'}>Quality KPIs</div>
            </Divider>

            <Card title={records ? `Total reports: ${records.data.length}` : 'Total reports:'}>
                <div className={'flex flex-row justify-between'}>
                    <div className={'w-1/2 px-4'}>
                        <Card title={records ? `Reasons: ${records.count}` : 'Reasons:'}>
                            <Table
                                key={'record-reasons'}
                                columns={[
                                    {
                                        title: 'Reason',
                                        dataIndex: 'reason',
                                        key: 'reason',
                                    },
                                    {
                                        title: 'Issues',
                                        dataIndex: 'total_by_reason',
                                        key: 'total_by_reason',
                                    },
                                ]}
                                loading={loading}
                                dataSource={records && records.reasons}
                                pagination={false}
                            />
                        </Card>
                    </div>

                    <div className={'w-1/2 px-4'}>
                        <Card
                            title={[
                                <span className={'px-4'} key={'total_sales_order'}>
                                    Sales Orders: {records && records.sales_orders.length}
                                </span>,
                                <span className={'px-4'} key={'total_sales_order'}>
                                    Items: {records && records.total_items}
                                </span>,
                            ]}
                        >
                            <Table
                                key={'record-products'}
                                columns={[
                                    {
                                        title: 'Sales Order',
                                        dataIndex: 'sale_order',
                                        key: 'sale_order',
                                    },
                                    {
                                        title: 'Items',
                                        dataIndex: 'total_by_sales_order',
                                        key: 'total_by_sales_order',
                                    },
                                ]}
                                loading={loading}
                                dataSource={records && records.sales_orders}
                                pagination={false}
                            />
                        </Card>
                    </div>
                </div>

                <div className={'w-full p-4'}>
                    <Table
                        key={'record-reasons'}
                        columns={columns}
                        loading={loading}
                        dataSource={records && records.data}
                        pagination={false}
                    />
                </div>
            </Card>
            <Drawer
                title="Details."
                width={'50%'}
                closable
                visible={drawer}
                placement={'right'}
                onClose={() => {
                    setDataDrawer(null);
                    setDrawer(false);
                }}
            >
                <Spin spinning={loading}>
                    <Descriptions column={2} bordered>
                        {dataDrawer && (
                            <>
                                <Descriptions.Item label="Sales Order">{dataDrawer.sales_order.name}</Descriptions.Item>
                                <Descriptions.Item label="Items">
                                    {dataDrawer.part_quality_items.length}
                                </Descriptions.Item>
                                <Descriptions.Item label="Report Date">
                                    {dayjs(dataDrawer.created_time).format('LLLL')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Contact / Account">
                                    {dataDrawer.sales_order.contact.fullname} / {dataDrawer.sales_order.contact.account}
                                </Descriptions.Item>
                                <Descriptions.Item label="Items" span={2}>
                                    <Table rowKey={'id'} dataSource={dataDrawer.part_quality_items} pagination={false}>
                                        <Table.Column
                                            title="Name"
                                            dataIndex="name"
                                            key="name"
                                            render={(t, record: any) => {
                                                return <>{record.sales_order_item.name}</>;
                                            }}
                                        />
                                        <Table.Column
                                            title="Quantity"
                                            dataIndex="quantity"
                                            key="quantity"
                                            render={(t, record: any) => {
                                                return <>{record.quantity}</>;
                                            }}
                                        />
                                        <Table.Column
                                            title="Reasons"
                                            dataIndex="Reasons"
                                            key="Reasons"
                                            render={(t, record: any) => {
                                                return record.reason.map((r: string) => {
                                                    return <p key={r}>{r}</p>;
                                                });
                                            }}
                                        />
                                    </Table>
                                </Descriptions.Item>
                                <Descriptions.Item label="Fault" span={2}>
                                    <Radio.Group
                                        onChange={e => {
                                            const fault = e.target.value;
                                            setDataDrawer({ ...dataDrawer, fault });
                                        }}
                                        value={dataDrawer.fault}
                                    >
                                        <Radio value={'Vendor'}>Vendor's fault</Radio>
                                        <Radio value={'PB'}>PB fault</Radio>
                                        <Radio value={'Other'}>Other</Radio>
                                    </Radio.Group>
                                </Descriptions.Item>
                            </>
                        )}
                    </Descriptions>

                    <div className={'flex flex-row mt-4 justify-center'}>
                        <Button
                            type={'primary'}
                            onClick={() => {
                                if (dataDrawer) {
                                    setLoading(true);
                                    const payload = {
                                        fault: dataDrawer.fault,
                                    };
                                    ReportStore.updatePartQualityReport(dataDrawer.id, payload)
                                        .then(() => {
                                            message.success('Report updated successfully');
                                            getReports(searchParams);
                                            setLoading(false);
                                        })
                                        .catch(() => {
                                            setLoading(false);
                                        });
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </div>
                </Spin>
            </Drawer>
        </>
    );
});
