import React from 'react';
import { Button, Checkbox, Col, Radio, Row, Space } from 'antd';
import { ArrowLeftOutlined, CloudDownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface Props {
    handleGoBack: () => void;
    sales_order_name: string;
    created_time: string;
    downloading?: boolean;
    full_name?: string;
    downloadAllFiles?: (quality_issue_id: number, sales_order_name: string) => void;
    quality_issue_id?: number;
}

export const ReworkHeader = (props: Props) => {
    return (
        <Row className={'shadow p-2'}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                <div className={'flex flex-row item-center justify-left'}>
                    <ArrowLeftOutlined
                        style={{
                            fontSize: 22,
                        }}
                        onClick={() => props.handleGoBack()}
                    />
                    <div className={'flex flex-col font-bold ml-2'}>Create Sales Order Rework</div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <div className={'flex flex-row justify-end'}>
                    <div className={'mx-2'}>
                        <span>
                            {dayjs(props.created_time).format('YYYY-MM-DD HH:mm:ss')} - By {props.full_name}
                        </span>
                    </div>

                    {props.quality_issue_id && props.downloadAllFiles && (
                        <Button
                            type={'link'}
                            className="mx-2"
                            onClick={() => {
                                if (props.quality_issue_id && props.downloadAllFiles) {
                                    props.downloadAllFiles(props.quality_issue_id, props.sales_order_name);
                                }
                            }}
                        >
                            {props.downloading ? (
                                <div>
                                    <LoadingOutlined /> Downloading
                                </div>
                            ) : (
                                <div>
                                    <CloudDownloadOutlined /> Download All Files
                                </div>
                            )}
                        </Button>
                    )}
                </div>
            </Col>
        </Row>
    );
};
