import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, notification, Table } from 'antd';
import dayjs from 'dayjs';
import {
    CloudDownloadOutlined,
    DownloadOutlined,
    DownOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { IQualityIssueListStaff, IPartQualityItem } from '@partsbadger/types';
import FileDownload from 'js-file-download';
import { get } from '@partsbadger/utils';
import { get as get2 } from '../../../shared';
import { useHistory } from 'react-router-dom';
import { QualityIssueStore } from '../../../stores/QualityIssuesStore';

interface IProps {
    sales_order_id: string;
}

export const QualityIssuesList = (props: IProps) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IQualityIssueListStaff[]>([]);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        getQualityIssues();
    }, []);

    const downloadAllFiles = async (quality_issue_id: number, sales_order_name: string) => {
        setDownloading(true);
        await QualityIssueStore.download_all_files(quality_issue_id, sales_order_name);
        setDownloading(false);
    };

    const getQualityIssues = () => {
        const url = `/staff/sales-orders/${props.sales_order_id}/get-quality-issues/`;
        setLoading(true);
        get(url)
            .then((response: React.SetStateAction<IQualityIssueListStaff[]>) => {
                setData(response);
            })
            .catch(() => {
                notification.error({ message: 'Error getting data.', duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            <Table
                columns={[
                    {
                        title: 'Affected Parts',
                        key: 'affected_parts',
                        render: (r: IQualityIssueListStaff) => {
                            return r.part_quality_items.map((item: IPartQualityItem, index: number) => {
                                return (
                                    <p key={index}>
                                        <b>{item.sales_order_item.name}</b> - {item.quantity}{' '}
                                        <i>{item.reason.join(', ')}</i>
                                    </p>
                                );
                            });
                        },
                    },
                    {
                        title: 'Issues',
                        key: 'issues',
                        render: (r: IQualityIssueListStaff) => {
                            return r.issues;
                        },
                    },
                    {
                        title: 'Rework Required Type',
                        key: 'rework_required_type',
                        render: (r: IQualityIssueListStaff) => {
                            const messages: { [key: string]: { [key: string]: string } } = {
                                true: {
                                    true: 'REDO, RMA',
                                    false: 'REDO',
                                },
                                false: {
                                    true: 'RMA',
                                    false: '',
                                },
                            };

                            return messages[r.redo_required.toString()][r.rma_required.toString()] || '';
                        },
                    },
                    {
                        title: 'Fault Of / Comment',
                        key: 'fault_of',
                        render: (r: IQualityIssueListStaff) => {
                            return [r.fault, r.reason_fault].filter(Boolean).join(' / ');
                        },
                    },
                    {
                        title: 'Submissions Date',
                        key: 'submissions_date',
                        render: (r: IQualityIssueListStaff) => {
                            return (
                                <div>
                                    <div>{dayjs(r.created_time).format('YYYY-MM-DD HH:mm:ss')}</div>
                                    <div className={'italic'}>By {r.owner.fullname}</div>
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Actions',
                        key: 'actions',
                        render: (r: IQualityIssueListStaff) => {
                            const menu = (
                                <Menu>
                                    <Menu.Item
                                        onClick={() =>
                                            history.push(`/quality-issues/${r.zoho_id ? r.zoho_id : r.id}/rework`)
                                        }
                                        icon={<PlusCircleOutlined />}
                                    >
                                        Create Rework
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => downloadAllFiles(r.id, r.sales_order.name)}
                                        icon={downloading ? <LoadingOutlined /> : <CloudDownloadOutlined />}
                                    >
                                        Download All Files
                                    </Menu.Item>
                                </Menu>
                            );
                            return (
                                <Dropdown overlay={menu}>
                                    <span className={'link flex flex-row items-center'}>
                                        Actions <DownOutlined />
                                    </span>
                                </Dropdown>
                            );
                        },
                    },
                ]}
                dataSource={data}
                loading={loading}
                pagination={false}
            />
        </div>
    );
};
