var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MailOutlined, MinusCircleOutlined, PlusCircleOutlined, PrinterOutlined, SaveOutlined, } from '@ant-design/icons';
import { ErrorRender, FormErrorParser, ShippingCarriersPurchasesOrders, ShippingMethodsPurchasesOrders, } from '@partsbadger/library';
import { Alert, Button, Card, Checkbox, Col, Form, Input, InputNumber, notification, Popconfirm, Radio, Row, Skeleton, Tag, } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { DownloadFile, get, patch, post } from '@partsbadger/utils';
import { forEach } from 'lodash';
import axios from 'axios';
var formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
    },
};
var CARRIER_WITH_API_INTEGRATION = ['FedEX', 'UPS', 'USPS', 'DHL'];
var Shipment = observer(function (props) {
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = React.useState(false), isGettingRates = _b[0], setIsGettingRates = _b[1];
    var _c = React.useState(false), isCreatingShipment = _c[0], setIsCreatingShipment = _c[1];
    var _d = React.useState(false), isPrinting = _d[0], setIsPrinting = _d[1];
    var form = Form.useForm()[0];
    var _e = React.useState(null), box = _e[0], setBox = _e[1];
    var _f = React.useState({
        rates: [],
        messages: [],
    }), shippingRates = _f[0], setShippingRates = _f[1];
    var _g = React.useState('unknown'), hasApiIntegration = _g[0], setHasApiIntegration = _g[1];
    var cleanRates = function () {
        setShippingRates({
            rates: [],
            messages: [],
        });
        setHasApiIntegration('unknown');
    };
    var getShipment = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get('/staff/outtakes/' + props.outtakeId + '/shipment/')
                        .then(function (data) {
                        setBox(data);
                    })
                        .catch(function (err) {
                        notification.error({
                            key: 'form-errors',
                            duration: 8,
                            message: 'Error getting shipment',
                            description: React.createElement(ErrorRender, { error: err }),
                            placement: 'top',
                            maxCount: 1,
                        });
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateShipment = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    cleanRates();
                    return [4 /*yield*/, patch('/staff/outtakes/' + props.outtakeId + '/update-shipment/', payload)
                            .then(function (data) {
                            setBox(data.box);
                            setShippingRates(data.shipping_rates);
                            setHasApiIntegration(data.has_api_integration);
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error updating shipment',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var createShipment = function (rate_object_id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsCreatingShipment(true);
                    return [4 /*yield*/, post('/staff/outtakes/' + props.outtakeId + '/create-shipment/', {
                            rate_object_id: rate_object_id,
                        })
                            .then(function (data) {
                            setBox(data);
                            cleanRates();
                            notification.success({
                                duration: 8,
                                message: 'Shipment sent to carrier',
                                description: 'Shipping label created',
                            });
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error updating shipment',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .finally(function () {
                            setIsCreatingShipment(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var createManualShipment = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsCreatingShipment(true);
                    return [4 /*yield*/, post('/staff/outtakes/' + props.outtakeId + '/complete-shipment/', {})
                            .then(function (data) {
                            setBox(data);
                            cleanRates();
                            notification.success({
                                duration: 8,
                                message: "Shipment marked as 'Shipped'",
                                description: 'The shipment has been marked as shipped',
                            });
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error updating shipment',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .finally(function () {
                            setIsCreatingShipment(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var cancelShipment = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsCreatingShipment(true);
                    return [4 /*yield*/, post('/staff/outtakes/' + props.outtakeId + '/cancel-shipment/')
                            .then(function (data) {
                            setBox(data);
                            notification.success({
                                duration: 8,
                                message: 'Shipment canceled',
                                description: 'Shipment canceled',
                            });
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error canceling shipment, please try again or contact support',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .finally(function () {
                            setIsCreatingShipment(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var sendCustomerNotification = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsCreatingShipment(true);
                    return [4 /*yield*/, post('/staff/outtakes/' + props.outtakeId + '/send-customer-notification/')
                            .then(function () {
                            notification.success({
                                duration: 8,
                                message: 'Notification sent',
                                description: 'Notification sent to customer',
                            });
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error canceling shipment, please try again or contact support',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .finally(function () {
                            setIsCreatingShipment(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteShipment = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsCreatingShipment(true);
                    return [4 /*yield*/, post('/staff/outtakes/' + props.outtakeId + '/delete-shipment/')
                            .then(function () {
                            props.handleClose();
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error deleting shipment, please try again or contact support',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .finally(function () {
                            setIsCreatingShipment(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var printLabel = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsPrinting(true);
                    return [4 /*yield*/, post('/staff/outtakes/' + props.outtakeId + '/print-label/')
                            .then(function () {
                            notification.success({
                                key: 'form-success',
                                duration: 8,
                                message: 'Label printed successfully',
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'Error printing label, please try again or contact support',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })
                            .finally(function () {
                            setIsPrinting(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var downloadLabels = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            forEach(box === null || box === void 0 ? void 0 : box.packages, function (p) { return __awaiter(void 0, void 0, void 0, function () {
                var response;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!((_a = p.label) === null || _a === void 0 ? void 0 : _a.file)) return [3 /*break*/, 2];
                            return [4 /*yield*/, axios.get(p.label.file, { responseType: 'blob' })];
                        case 1:
                            response = _b.sent();
                            DownloadFile(response.data, "label-".concat(p.id, ".zpl"));
                            _b.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (props.outtakeId) {
            setIsLoading(true);
            getShipment().then(function () {
                setIsLoading(false);
            });
        }
    }, [props.outtakeId]);
    if (!box) {
        return React.createElement(Skeleton, null);
    }
    var PackageDetails = function () { return (React.createElement(Form.List, { name: "packages" }, function (fields, _a, _b) {
        var add = _a.add, remove = _a.remove;
        var errors = _b.errors;
        return (React.createElement("div", { className: 'flex flex-col w-full' },
            fields.map(function (field, _index) { return (React.createElement(Row, { key: "index-".concat(_index) },
                React.createElement(Form.Item, { labelAlign: 'left', label: _index == 0 ? 'Packages Information' : '', required: true, style: {
                        marginBottom: 0,
                    } },
                    React.createElement("div", { className: 'flex md:flex-row flex-col' },
                        React.createElement(Form.Item, __assign({ labelAlign: 'left' }, formItemLayout, field, { name: [field.name, 'package_length'], rules: [
                                {
                                    required: true,
                                    message: 'Length is required',
                                },
                            ], style: {
                                marginBottom: 0,
                            } }),
                            React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Length (IN)', tabIndex: parseInt("".concat(field.key) + '1') })),
                        React.createElement(Form.Item, __assign({ labelAlign: 'left' }, formItemLayout, field, { name: [field.name, 'package_width'], rules: [
                                {
                                    required: true,
                                    message: 'Width is required',
                                },
                            ], style: {
                                marginBottom: 0,
                            } }),
                            React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Width (IN)', tabIndex: parseInt("".concat(field.key) + '2') })),
                        React.createElement(Form.Item, __assign({ labelAlign: 'left' }, formItemLayout, field, { name: [field.name, 'package_height'], rules: [
                                {
                                    required: true,
                                    message: 'Height is required',
                                },
                            ], style: {
                                marginBottom: 0,
                            } }),
                            React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Height (IN)', tabIndex: parseInt("".concat(field.key) + '3') })),
                        React.createElement(Form.Item, __assign({ labelAlign: 'left' }, formItemLayout, field, { name: [field.name, 'package_weight'], rules: [
                                {
                                    required: true,
                                    message: 'Weight is required',
                                },
                            ], style: {
                                marginBottom: 0,
                            } }),
                            React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Weight (LB)', tabIndex: parseInt("".concat(field.key) + '4') })),
                        React.createElement(Form.Item, __assign({ labelAlign: 'left' }, formItemLayout, field, { name: [field.name, 'id'], style: {
                                marginBottom: 0,
                            }, hidden: true }),
                            React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'ID', tabIndex: -1 })),
                        React.createElement(Form.Item
                        // label={'Actions'}
                        , __assign({ 
                            // label={'Actions'}
                            labelAlign: 'left' }, formItemLayout, { style: {
                                marginBottom: 0,
                            } }), box.status != 'Shipped' && (React.createElement("div", { className: 'text-center' },
                            React.createElement(MinusCircleOutlined, { style: {
                                    color: 'red',
                                }, className: "dynamic-delete-button", onClick: function () { return remove(field.name); }, tabIndex: -1 })))))))); }),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24, style: {
                    // backgroundColor: '#f0f2f5',
                    } },
                    React.createElement(Form.Item, null,
                        React.createElement(Button, { tabIndex: -1, type: "dashed", style: {
                                width: '100%',
                                marginTop: '20px',
                                color: 'green',
                                borderColor: 'green',
                            }, onClick: function () {
                                add({
                                    package_length: undefined,
                                    package_width: undefined,
                                    package_height: undefined,
                                    package_weight: undefined,
                                });
                            }, icon: React.createElement(PlusCircleOutlined, null) }, "Add Box"),
                        React.createElement(Form.ErrorList, { errors: errors }))))));
    })); };
    var ShippingRates = function () { return (React.createElement(Form.Item, { shouldUpdate: true }, function () { return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { span: 24 },
                React.createElement("div", { style: {
                        width: '100%',
                    } },
                    !isGettingRates && shippingRates.messages.length > 0 && (React.createElement("div", { className: 'flex flex-col items-start justify-center w-full my-5' }, shippingRates.messages.map(function (message, index) {
                        return (React.createElement("div", { key: message.code, className: 'mt-2' },
                            React.createElement(Alert, { key: index, message: message.text })));
                    }))),
                    !isGettingRates && hasApiIntegration == 'no' && (React.createElement("div", { className: 'flex flex-col items-start justify-center w-full my-5' },
                        React.createElement(Row, { className: 'flex flex-row items-center justify-between border  py-2 px-1 w-full' },
                            React.createElement(Col, null, box === null || box === void 0 ? void 0 : box.carrier),
                            React.createElement(Col, null,
                                React.createElement("div", { style: {
                                        textAlign: 'left',
                                    } }, "\u00A0")),
                            React.createElement(Col, null,
                                React.createElement("div", null, box === null || box === void 0 ? void 0 : box.shipping_cost)),
                            React.createElement(Col, null,
                                React.createElement(Button, { onClick: function () {
                                        createManualShipment();
                                    }, loading: isCreatingShipment }, "Submit"))))),
                    shippingRates.rates.map(function (rate) {
                        var isCustomerPreferred = rate.servicelevel.name == box.shipping_method;
                        return (React.createElement("div", { key: rate.object_id },
                            React.createElement(Row, { className: 'flex flex-row items-center justify-between border  py-2 px-1', style: {
                                    backgroundColor: isCustomerPreferred ? '#e6f7ff' : 'initial',
                                } },
                                React.createElement(Col, null,
                                    React.createElement("img", { src: rate.provider_image_75, alt: rate.provider })),
                                React.createElement(Col, null,
                                    React.createElement("div", { style: {
                                            textAlign: 'left',
                                        } }, rate.servicelevel.name)),
                                React.createElement(Col, null,
                                    React.createElement("div", null, rate.amount)),
                                React.createElement(Col, null,
                                    React.createElement(Button, { type: isCustomerPreferred ? 'primary' : 'default', onClick: function () {
                                            createShipment(rate.object_id);
                                        }, loading: isCreatingShipment }, "Submit")))));
                    })))))); })); };
    return (React.createElement(Card, { title: React.createElement("div", { className: 'flex flex-row' },
            "Shipment:",
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(Tag, { color: box.status == 'Shipped' ? 'green' : 'yellow' }, box.status),
                box.status == 'Shipped' && React.createElement("a", null, box.tracking_number))), extra: React.createElement("div", { className: 'flex flex-row items-center justify-between' },
            box.status === 'Shipped' && (React.createElement(Button.Group, null,
                React.createElement(Popconfirm, { placement: "bottomLeft", title: 'Are you sure you want to cancel this shipment?', onConfirm: cancelShipment, okText: "Yes, Cancel Shipment", cancelText: "No", disabled: box.status !== 'Shipped' },
                    React.createElement(Button, { style: { float: 'right' } }, "Cancel Shipment")),
                React.createElement(Popconfirm, { placement: "bottomLeft", title: 'Are you sure you want to send a notification to the customer?', onConfirm: sendCustomerNotification, okText: "Yes, resend notification", cancelText: "No", disabled: box.status !== 'Shipped' },
                    React.createElement(Button, { key: 'send-customer-notification', type: 'default', style: { float: 'right' }, loading: isPrinting, icon: React.createElement(MailOutlined, null) }, "Send Customer Notification")),
                React.createElement(Button, { key: 'print', type: 'default', style: { float: 'right' }, onClick: downloadLabels, loading: isPrinting, icon: React.createElement(PrinterOutlined, null), disabled: box.status !== 'Shipped' }, "Download Labels"),
                React.createElement(Button, { key: 'print', type: 'primary', style: { float: 'right' }, onClick: printLabel, loading: isPrinting, icon: React.createElement(PrinterOutlined, null) }, "Print Label"))),
            box.status !== 'Shipped' && (React.createElement(React.Fragment, null,
                React.createElement(Popconfirm, { placement: "bottomLeft", title: 'Are you sure you want to delete this shipment?', onConfirm: deleteShipment, okText: "Yes, Delete Shipment", cancelText: "No" },
                    React.createElement(Button, { danger: true, style: { float: 'right' } }, "Delete Shipment?"))))) },
        React.createElement(Form, __assign({ title: box.status, disabled: box.status === 'Shipped', form: form }, formItemLayout, { initialValues: {
                carrier: box.carrier,
                shipping_method: box.shipping_method,
                shipping_account: box.shipping_account,
                notes: box.notes,
                internal_notes: box.internal_notes,
                shipping_cost: box.shipping_cost,
                late_shipping_cost: box.late_shipping_cost,
                is_late_shipment: box.is_late_shipment,
                account_type: box.account_type,
                tracking_number: box.tracking_number,
                bill_to_customer: box.bill_to_customer,
                boxes_included: box.boxes_included,
                notification_opt_out: box.notification_opt_out,
                packages: box.packages.map(function (p) {
                    var _a;
                    return {
                        id: p.id,
                        package_height: p.package_height,
                        package_length: p.package_length,
                        package_weight: p.package_weight,
                        package_width: p.package_width,
                        label: (_a = p.label) === null || _a === void 0 ? void 0 : _a.file,
                    };
                }),
            }, onFinish: function (values) {
                var payload = {
                    carrier: values.carrier,
                    shipping_method: values.shipping_method,
                    shipping_account: values.shipping_account,
                    notes: values.notes,
                    internal_notes: values.internal_notes,
                    is_late_shipment: values.is_late_shipment,
                    bill_to_customer: values.bill_to_customer,
                    opt_out_packing_slip: values.opt_out_packing_slip,
                    packages: values.packages,
                    notification_opt_out: values.notification_opt_out,
                    account_type: values.account_type,
                };
                if (values.boxes_included) {
                    payload.boxes_included = values.boxes_included;
                }
                if (values.shipping_cost) {
                    payload.shipping_cost = values.shipping_cost;
                }
                if (values.tracking_number) {
                    payload.tracking_number = values.tracking_number;
                }
                setIsLoading(true);
                updateShipment(payload).then(function () {
                    setIsLoading(false);
                });
            }, onFinishFailed: function (errorInfo) {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                    placement: 'top',
                    maxCount: 1,
                });
            }, onValuesChange: function () {
                // cleanRates();
            } }),
            React.createElement(Row, null,
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: 'Carrier', name: 'carrier' },
                        React.createElement(ShippingCarriersPurchasesOrders, { tabIndex: -1 }))),
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: "Shipping Method", name: 'shipping_method' },
                        React.createElement(ShippingMethodsPurchasesOrders, { tabIndex: -1 }))),
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement("div", { style: {
                            maxWidth: '80%',
                            margin: '0 auto',
                        } },
                        React.createElement(Form.Item, { labelAlign: 'left', label: "Shipping Account", name: 'account_type' },
                            React.createElement(Radio.Group, null,
                                React.createElement(Radio, { value: 'customer', tabIndex: -1 }, "Customer"),
                                React.createElement(Radio, { value: 'pb', tabIndex: -1 }, "Parts Badger"))))),
                React.createElement(Form.Item, { dependencies: ['account_type'], noStyle: true }, function (_a) {
                    var getFieldValue = _a.getFieldValue;
                    return (getFieldValue('account_type') === 'customer' && (React.createElement(Col, { md: 24, lg: 6 },
                        React.createElement(Form.Item, __assign({ labelAlign: 'left', label: "Account Number", name: 'shipping_account', initialValue: box.shipping_account }, formItemLayout),
                            React.createElement(Input, { style: {
                                    width: '90%',
                                }, tabIndex: -1 })))));
                }),
                React.createElement(Form.Item, { dependencies: ['account_type'], noStyle: true }, function (_a) {
                    var getFieldValue = _a.getFieldValue;
                    return (getFieldValue('account_type') === 'pb' && (React.createElement(Col, { md: 24, lg: 6 },
                        React.createElement(Form.Item, __assign({ labelAlign: 'left', label: "Billing To", name: 'bill_to_customer', style: {
                                paddingLeft: '10px',
                            } }, formItemLayout),
                            React.createElement(Radio.Group, null,
                                React.createElement(Radio, { value: true, tabIndex: -1 }, "Customer"),
                                React.createElement(Radio, { value: false, tabIndex: -1 }, "Parts Badger"))))));
                })),
            React.createElement(Row, { className: 'shadow p-2' },
                React.createElement(Form.Item, { dependencies: ['carrier'], noStyle: true }, function (_a) {
                    var getFieldValue = _a.getFieldValue;
                    return CARRIER_WITH_API_INTEGRATION.includes(getFieldValue('carrier')) ? (React.createElement(PackageDetails, null)) : (React.createElement(React.Fragment, null,
                        React.createElement(Col, { md: 24, lg: 8 },
                            React.createElement(Form.Item, { labelAlign: 'left', label: "Tracking Number", name: 'tracking_number' },
                                React.createElement(Input, null))),
                        React.createElement(Col, { md: 24, lg: 8 },
                            React.createElement(Form.Item, { labelAlign: 'left', label: "Shipping Cost", name: 'shipping_cost', rules: [
                                    {
                                        required: true,
                                        message: 'Please input the shipping cost',
                                    },
                                ] },
                                React.createElement(InputNumber, { style: {
                                        width: '100%',
                                    } }))),
                        React.createElement(Col, { md: 24, lg: 8 },
                            React.createElement(Form.Item, { labelAlign: 'left', label: "Boxes Included", name: 'boxes_included', rules: [
                                    {
                                        required: true,
                                        message: 'Please input the number of boxes included',
                                    },
                                ] },
                                React.createElement(InputNumber, { style: {
                                        width: '100%',
                                    } })))));
                })),
            React.createElement(Row, null,
                React.createElement(Col, { md: 24, lg: 12 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: "Customer Shipping Notes", name: 'notes' },
                        React.createElement(Input.TextArea, { tabIndex: 100 }))),
                React.createElement(Col, { md: 24, lg: 12 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: "Internal Shipping Notes", name: 'internal_notes' },
                        React.createElement(Input.TextArea, { tabIndex: 101 })))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement(Form.Item, { name: 'notification_opt_out', valuePropName: "checked" },
                        React.createElement(Checkbox, { tabIndex: -1 }, "Opt Out of Packing Slip?"))),
                React.createElement(Form.Item, { dependencies: ['account_type', 'bill_to_customer'], noStyle: true }, function (_a) {
                    var getFieldValue = _a.getFieldValue;
                    return (getFieldValue('bill_to_customer') === false && (React.createElement(Col, { md: 24, lg: 6 },
                        React.createElement(Form.Item, { shouldUpdate: true }, function () { return (React.createElement(Form.Item, { name: 'is_late_shipment', valuePropName: 'checked' },
                            React.createElement(Checkbox, { tabIndex: -1 }, " Is this a late shipment? "))); }))));
                })),
            box.status != 'Shipped' && (React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { shouldUpdate: true }, function (_a) {
                        var getFieldValue = _a.getFieldValue;
                        return (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10" },
                            React.createElement(Button, { type: "primary", icon: React.createElement(SaveOutlined, null), loading: isLoading, onClick: function () {
                                    form.submit();
                                } }, CARRIER_WITH_API_INTEGRATION.includes(getFieldValue('carrier'))
                                ? 'Get Rates'
                                : 'Save Changes')));
                    })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(ShippingRates, null))),
            box.status == 'Shipped' && parseFloat(box.shipping_cost_carrier) > 0 && (React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Alert, { type: 'success', message: React.createElement("div", null,
                            React.createElement("div", null,
                                " Total Shipping Cost: $",
                                box.shipping_cost_carrier),
                            React.createElement("div", null,
                                " Customer Billed Shipping Cost: $",
                                box.shipping_cost)) })))))));
});
export default Shipment;
