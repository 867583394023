import React, { useEffect, useState } from 'react';

import {
    ArrowLeftOutlined,
    CalendarOutlined,
    HomeOutlined,
    NumberOutlined,
    OrderedListOutlined,
    PullRequestOutlined,
    ReadOutlined,
    UserOutlined,
    WalletOutlined,
    DownloadOutlined,
    ThunderboltFilled,
    ScheduleOutlined,
    MessageOutlined,
    UploadOutlined,
    MinusCircleOutlined,
    IssuesCloseOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Input,
    List,
    message,
    Modal,
    notification,
    Row,
    Skeleton,
    Table,
    Tabs,
    DatePicker,
    Select,
    Upload,
    Menu,
} from 'antd';
import { ColumnProps } from 'antd/es/table';

import { Link, useHistory, useParams } from 'react-router-dom';
import {
    ErrorRender,
    NoteCreateProps,
    NotesListCard,
    Price,
    Addresses,
    is_valid_address,
    AddressForm,
    AddressProps,
} from '@partsbadger/library';
import { post } from '@partsbadger/utils';
import { get, put } from 'shared';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import Shipments from '../../../components/SalesOrder/Shipments';
import QualityDocs from '../../../components/SalesOrder/QualityDocs';
import Invoices from '../../../components/SalesOrder/Invoices';
import { Chat } from '@partsbadger/quote-tool';

import { INotes, IOrderLineItemProps, VendorProductsProps } from '../../../../utils/types';
import { observer } from 'mobx-react';
import moment from 'moment';
import dayjs from 'dayjs';
import { OrderLineItemProps, ItypeUpdateNotification } from '@partsbadger/types/lib/SalesOrderInterfaces';
import { NotesForm } from '@partsbadger/library/lib/Form/NotesForm';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { hasGroup } from '../../../components/User';
import { UserStore } from '../../../stores/UserStore';

import OrderModalHistory from '../../../components/SalesOrder/OrderModalHistory';

import TotalUnreadMessagesAlert from '../TicketSystem/TotalUnreadMessagesAlert';
import TicketMainPage from '../TicketSystem/TicketMainPage';
import CancelItemsHistory from './CancelItems/CancelItemsHistory';

const FileDownload = require('js-file-download');

import RequestOrderAdjustment from '../../../components/SalesOrder/RequestOrderAdjustment';

import { TicketStore } from '../../../stores/TicketStore';
import OrderLineItemModalHistory from '../../../components/SalesOrder/OrderLineItemModalHistory';
import { QualityIssuesList } from '../QualityIssues/QualityIssuesList';

const { TabPane } = Tabs;
const { TextArea } = Input;

type Props = FormComponentProps;

type Params = {
    sales_order_id: string;
};

interface ISelectedDate {
    id: number;
    name: string;
    date_target: string;
}

const Details = observer((props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [orderItems, setOrderItems] = useState<any>();
    const [loadingProductionInformation, setLoadingProductionInformation] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalShipping, setShowModalShipping] = useState<boolean>(false);
    const [request_type_update, setRequest_type_update] = useState<ItypeUpdateNotification>();
    const [showModalNotesByItem, setShowModalNotesByItem] = useState<boolean>(false);
    const [notesByItem, setNotesByItem] = useState<{ id: number; item: string; notes: INotes[] } | null>(null);
    const [notes, setNotes] = useState<string>('');
    const [typeShipment, settypeShipment] = useState<string>('');
    const [notesShipment, setNotesShipment] = useState<string>('');
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [showAddNewAddress, setShowAddNewAddress] = useState(false);
    const [selectLineItems, setSelectLineItems] = useState<IOrderLineItemProps[]>([]);
    const [keyLineItems, setKeyLineItems] = useState<Array<any>>([]);
    const [selectedDate, setSelectedDate] = useState<ISelectedDate[]>([]);
    const [showProductFeedbackModal, setShowProductFeedbackModal] = useState<boolean>(false);
    const [itemForFeedback, setItemForFeedback] = useState<IOrderLineItemProps | null>(null);
    const [currentFeedback, setCurrentFeedback] = useState<string>('');
    const [fileList, setFileList] = useState<any[]>([]);
    const [showRequestOrderAdjustment, setShowRequestOrderAdjustment] = useState<boolean>(false);

    const [showHistoryOrderModal, setShowHistoryOrderModal] = useState<boolean>(false);
    const [showHistoryOrderLineItemModalById, setShowHistoryOrderLineItemModalById] = useState<number | null>(null);

    const [totalUnreadMessagesSalesRequest, setTotalUnreadMessagesSalesRequest] = useState<number>(0);
    const [totalCancelledItems, setTotalCancelledItems] = useState<number>(0);

    const UploadProps = {
        name: 'file',
        multiple: true,
        accept: 'pdf,.jpeg,.png,.jpg',
        onRemove: () => {
            setFileList([]);
        },
        beforeUpload: (file: any, newFileList: any[]) => {
            setFileList([...fileList, ...newFileList]);
            return false;
        },
        fileList,
    };

    const { sales_order_id } = useParams<Params>();

    const history = useHistory();

    const back = () => {
        history.goBack();
    };
    const { addresses } = SalesOrderStore;

    async function getSalesOrderDetails(id: string | number) {
        setLoading(true);
        await SalesOrderStore.getById(id)
            .then(async response => {
                if (SalesOrderStore.order && SalesOrderStore.order?.line_items.length > 0) {
                    setOrderItems(SalesOrderStore.order.line_items);
                    await getVendorInformation(sales_order_id);
                    await getUnReadMessagesProductionSales(response?.zoho_id);
                }
            })
            .catch(error => {
                message.error(`Error loading tracking codes: ${error}`);
            });
        setLoading(false);
    }

    const getVendorInformation = async (id: any) => {
        setLoadingProductionInformation(true);
        setLoading(true);
        await SalesOrderStore.getTrackingNumbersBySalesOrderId(id)
            .then(data => {
                if (SalesOrderStore.order && SalesOrderStore.products_tracking_numbers.length > 0) {
                    setOrderItems(
                        mergeLineItemsByZohoId(
                            SalesOrderStore.order.line_items,
                            SalesOrderStore.products_tracking_numbers
                        )
                    );
                }
            })
            .catch(error => {
                message.error(`Error loading tracking codes: ${error}`);
            });

        await SalesOrderStore.getQualityDocumentsBySalesOrderId(id)
            .then(data => {
                setLoadingProductionInformation(false);
            })
            .catch(
                error => (
                    setLoadingProductionInformation(false), message.error(`Error loading quality documents: ${error}`)
                )
            );
        setLoading(false);
    };

    const getUnReadMessagesProductionSales = async (zohoId: string) => {
        if (zohoId) {
            const response = await TicketStore.getTotalUnreadMessages(zohoId, 'production-sales');

            setTotalUnreadMessagesSalesRequest(response);
        }
    };

    const getTotalItemsCancelled = () => {
        const url = `staff/sales-orders/${sales_order_id}/total-cancelled-items/`;
        setLoading(true);
        get(url).then((response: any) => {
            setTotalCancelledItems(response.data.total_cancelled_items);
        });
    };

    useEffect(() => {
        if (sales_order_id) {
            getSalesOrderDetails(sales_order_id);
            SalesOrderStore.getNotifications(sales_order_id);
            SalesOrderStore.getNotes(sales_order_id);
            SalesOrderStore.getShipmentsBySalesOrderId(sales_order_id);
            getTotalItemsCancelled();
        }
    }, [sales_order_id]);

    const mergeLineItemsByZohoId = (line_items: OrderLineItemProps[], vendor_products: VendorProductsProps[]) => {
        let tracking: VendorProductsProps[] = [];
        line_items.map((line_item: OrderLineItemProps) => {
            vendor_products.find((vendor_product: VendorProductsProps) => {
                if (vendor_product.zoho_id === line_item.product_zid && vendor_product) {
                    tracking.push(vendor_product);
                }
            });
            line_item.tracking_codes = tracking;
            tracking = [];
        });
        return line_items;
    };

    const downloadPdf = (order_id: string, order_name: string | undefined) => {
        setLoading(true);

        get(`staff/sales-orders/${order_id}/download/`, {}, { responseType: 'blob' })
            .then((response: any) => {
                const data = response.data;

                FileDownload(data, `${order_name}.pdf`);
                setLoading(false);
                notification.success({ message: 'Document downloaded' });
            })
            .catch((error: any) => notification.error(error));
    };
    const send_to_shipping_team = () => {
        const payload: {
            id: string;
            type_message: string;
            notes: string;
            files: any;
        } = {
            id: sales_order_id,
            type_message: typeShipment,
            notes: notesShipment,
            files: fileList ? fileList?.map(f => f) : [],
        };
        SalesOrderStore?.order?.id &&
            SalesOrderStore.notify_shipping_team(payload)
                .then(() => {
                    notification.success({ message: 'Message sent to the team' });
                    setFileList([]);
                    settypeShipment('');
                    setNotesShipment('');
                })
                .catch((error: any) => notification.error({ message: error }));
    };

    const saveProductFeedback = (lineItemId: string | number) => {
        setLoadingButton(true);
        put(`staff/sales-order-line-items/${lineItemId}/send-product-feedback/`, {
            product_feedback: currentFeedback,
        })
            .then((response: any) => {
                notification.success({ message: 'Feedback saved' });
                getSalesOrderDetails(sales_order_id);
            })
            .catch((error: any) => notification.error({ message: error }))
            .finally(() => setLoadingButton(false));
    };

    const columns: ColumnProps<IOrderLineItemProps>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (t, r: IOrderLineItemProps) => {
                return (
                    <>
                        <div className={'flex flex-row'}>
                            <div>{t !== 'CNC Part' ? t : ''}</div>
                        </div>
                        <div className={'text-md'}>{r.product_description}</div>

                        {hasGroup('Production') && (
                            <Button
                                type={'link'}
                                onClick={() => {
                                    if (r.notes) {
                                        setNotesByItem({ id: r.id, item: t, notes: r.notes });
                                        setShowModalNotesByItem(true);
                                    }
                                }}
                            >
                                {r.notes && r.notes.length > 0 ? (
                                    <Badge count={`${r.notes?.length} Notes`} overflowCount={100} />
                                ) : (
                                    'Add Note'
                                )}
                            </Button>
                        )}
                    </>
                );
            },
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_part_ship_date',
            key: 'required_part_ship_date',
            width: '10%',
            sorter: (a: any, b: any) =>
                Number(new Date(a.required_part_ship_date)) - Number(new Date(b.required_part_ship_date)),
        },
        {
            title: 'Required Partner Ship Date',
            dataIndex: 'required_partner_ship_date',
            key: 'required_partner_ship_date',
            width: '10%',
            render: (text, record) => {
                if (record.revised_partner_ship_date) {
                    return (
                        <>
                            <div className="line-through">{record.required_partner_ship_date}</div>
                            <span className="text-red-600">{record.revised_partner_ship_date}</span>
                        </>
                    );
                } else {
                    return record.required_partner_ship_date;
                }
            },
        },
        {
            title: 'Production Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (text, record) => {
                // 1. Part is in production and not exist yet
                // 2. Part is submitted partially US
                // 3. Part is submitted full to US
                // 4. Part is domestic process
                // 5  Part is submitted to Customer Partial
                // 6. Part is submitted to Customer in Full

                const production_record = SalesOrderStore.quality_documents.find(
                    item => item.product_zid == record.product_zid
                );
                if (production_record) {
                    const quantity_shipped = production_record.quantity_shipped;
                    const required_partner_ship_date = production_record.required_partner_ship_date;
                    const revised_ship_date = production_record.revised_ship_date;
                    const current_date = production_record.current_date;
                    if (required_partner_ship_date && quantity_shipped === 0 && !revised_ship_date) {
                        const datePartner = moment(required_partner_ship_date, 'YYYY-MM-DD');
                        const currentDate = moment(current_date, 'YYYY-MM-DD');
                        const daysDiff = datePartner.diff(currentDate, 'days');
                        if (daysDiff >= 0) {
                            return `On Time`;
                        } else {
                            return `Please Hold for updates`;
                        }
                    } else if (required_partner_ship_date && quantity_shipped === 0 && revised_ship_date) {
                        return `New Est. to Ship`;
                    } else if (SalesOrderStore.order?.status === 'Received in Full') {
                        return `Received in Full`;
                    } else if (SalesOrderStore.order?.status === 'Received in Full - Domestic Finish') {
                        return `Pending secondary`;
                    } else if (SalesOrderStore.order?.status.startsWith('Received - Under Review')) {
                        return `Under review, please hold`;
                    } else if (SalesOrderStore.order?.domestic_process_needed === true) {
                        return `Please hold for updates (shop)`;
                    } else if (
                        production_record.quantity_shipped > 0 &&
                        SalesOrderStore.order?.intake_status === 'Intaken None'
                    ) {
                        return `On way to WI`;
                    } else if (record.quantity - production_record.quantity_shipped <= 0) {
                        return `Shipped in Full to USA`;
                    } else {
                        return <span>No Data</span>;
                    }
                }
                return <span>--</span>;
            },
        },
        {
            title: 'Quantity Invoiced',
            dataIndex: 'invoiced_quantity',
            key: 'invoiced_quantity',
            align: 'center',
        },
        {
            title: 'Tracking Code to PB (WI)',
            dataIndex: 'vendor_tracking_code',
            key: 'vendor_tracking_code',
            align: 'center',
            render: (text, record: IOrderLineItemProps) => {
                return (
                    <>
                        {record.tracking_codes && record.tracking_codes.length > 0 ? (
                            <>
                                {record.tracking_codes.map((tracking: VendorProductsProps, index: number) => {
                                    const tracking_code = tracking.vendor_tracking_code
                                        ? tracking.vendor_tracking_code.split('/')[0]
                                        : null;
                                    return (
                                        <>
                                            {tracking.vendor_tracking_code !== '' ? (
                                                <>
                                                    <div key={index} className={'mt-2'}>
                                                        <div>
                                                            <b>{index + 1}. </b>
                                                            {tracking_code} - As of{' '}
                                                            {moment(tracking.vendor_created_time).format('M/D/YYYY')}
                                                        </div>
                                                        <div>
                                                            <a
                                                                title={'Try in DHL'}
                                                                target={'_blank'}
                                                                rel={'noreferrer'}
                                                                href={`https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_code}`}
                                                            >
                                                                DHL
                                                            </a>

                                                            <a
                                                                title={'Try in FedEx'}
                                                                style={{
                                                                    marginLeft: 5,
                                                                }}
                                                                target={'_blank'}
                                                                rel={'noreferrer'}
                                                                href={`https://www.fedex.com/fedextrack/?trknbr=${tracking_code}`}
                                                            >
                                                                FEDEX
                                                            </a>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={'mt-2'}>
                                                    {tracking.vendor_created_time ? (
                                                        <>
                                                            <b>{index + 1}. </b>As of{' '}
                                                            {moment(tracking.vendor_created_time).format('M/D/YYYY')}
                                                        </>
                                                    ) : (
                                                        'Pending'
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            'Pending'
                        )}
                    </>
                );
            },
        },
        {
            title: 'Part Status',
            dataIndex: 'shipping_status',
            key: 'shipping_status',
            align: 'center',
            render: (text, record) => {
                switch (true) {
                    case record.intake_status === 'Intaken Partial' && record.shipping_status === 'Shipped None':
                        return 'Received in Partial';
                    case record.intake_status === 'Intaken Full' && record.shipping_status === 'Shipped None':
                        return 'Received in Full';
                    case record.intake_status === 'Intaken Partial' && record.shipping_status === 'Shipped in Partial':
                        return 'Shipped in Partial';
                    case record.intake_status === 'Intaken Full' && record.shipping_status === 'Shipped in Full':
                        return 'Shipped in Full';
                    case record.intake_status === 'Intaken Partial' && !record.shipping_status:
                        return 'Received in Partial';
                    case record.intake_status === 'Intaken Full' && !record.shipping_status:
                        return 'Received in Full';
                    default:
                        return null;
                }
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },

        {
            title: 'Unit Price',
            dataIndex: 'list_price',
            key: 'list_price',
            align: 'center',
            render: (text, record) => <Price value={record.list_price} />,
        },
        {
            title: 'Discount Item',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
            render: (text, record) => <Price value={record.discount} />,
        },
        {
            title: 'Total Price',
            dataIndex: 'total_after_discount',
            key: 'total_after_discount',
            align: 'right',
            render: (text, record) => <Price value={record.total_after_discount} />,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (text, record: IOrderLineItemProps) => {
                const production_record = SalesOrderStore.quality_documents.find(
                    item => item.product_zid == record.product_zid
                );

                return (
                    <div className={'items-center'}>
                        {production_record && production_record.quantity_shipped > 0 && (
                            <Button
                                type="link"
                                onClick={() => {
                                    setCurrentFeedback(
                                        record.product_feedback !== undefined ? record.product_feedback : ''
                                    );
                                    setItemForFeedback(record);
                                    setShowProductFeedbackModal(true);
                                }}
                            >
                                {' '}
                                Submit Product Feedback
                            </Button>
                        )}
                        {hasGroup('Developer') && (
                            <div className={'ml-2'}>
                                <Button type={'link'} onClick={() => setShowHistoryOrderLineItemModalById(record.id)}>
                                    History Changes
                                </Button>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];

    const onSubmit = async (request_type_update: string) => {
        try {
            const requestData = {
                username: 'staff',
                first_name: 'staff',
                last_name: 'staff',
                company_name: 'staff',
                order: SalesOrderStore.order?.name,
                email: 'staff@gmail.com',
                notes: notes,
                type_update: request_type_update,
                line_items: selectedDate.length > 0 ? selectedDate : selectLineItems,
            };

            if (SalesOrderStore.order) {
                await SalesOrderStore.sendUpdateRequest(SalesOrderStore.order.id, requestData);

                message.success('Your Update request has been sent successfully');

                return true;
            }

            message.error('The order does not exist.');
        } catch (err) {
            message.error(`Error loading shipments: ${err}`);
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IOrderLineItemProps[]) => {
            setSelectLineItems(selectedRows);
            setKeyLineItems(selectedRowKeys);
        },
    };

    function capitalizeWords(str: string) {
        return str
            .replaceAll('_', ' ')
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    // Filtering columns by Role

    const filtered_columns = React.useMemo(() => {
        return columns.filter(c => {
            if (c.key == 'invoiced_quantity') {
                return UserStore.user?.group?.includes('Finance');
            }

            return true;
        });
    }, [UserStore.user?.group]);

    const order = SalesOrderStore.order;

    return (
        <Card
            bodyStyle={{ padding: 10 }}
            bordered={false}
            style={{
                marginBottom: 10,
            }}
        >
            {loading && (
                <div className="w-full m-4 py-8 text-center">
                    <Skeleton paragraph={{ rows: 4 }} />
                </div>
            )}
            {!loading && SalesOrderStore.order && (
                <>
                    <div style={{ margin: '0' }} className="detail-top-row flex flex-row justify-between">
                        <div className="flex items-center justify-start">
                            <ArrowLeftOutlined className="mr-2" style={{ fontSize: '21px' }} onClick={back} />
                            <span className="font-bold" style={{ fontSize: '18px' }}>
                                {SalesOrderStore.order.name}{' '}
                                <Badge count={SalesOrderStore.order.status} style={{ backgroundColor: 'green' }} />
                            </span>
                            <span className={'ml-2'}>
                                {hasGroup('Developer') && (
                                    <a onClick={() => setShowHistoryOrderModal(true)}>History Changes</a>
                                )}
                            </span>
                        </div>

                        {order?.id && showHistoryOrderModal && (
                            <OrderModalHistory id={order?.id} handleClose={() => setShowHistoryOrderModal(false)} />
                        )}

                        {showHistoryOrderLineItemModalById && (
                            <OrderLineItemModalHistory
                                id={showHistoryOrderLineItemModalById}
                                handleClose={() => setShowHistoryOrderLineItemModalById(null)}
                            />
                        )}

                        <div className="flex flex-row justify-between">
                            {keyLineItems && keyLineItems?.length > 0 && (
                                <div>
                                    <Button
                                        className="mr-2"
                                        id="action-button"
                                        type="primary"
                                        onClick={() => {
                                            setRequest_type_update({ type_notification: 'request_expedite' });
                                            setSelectedDate([]);
                                            setShowModal(!showModal);
                                        }}
                                        style={{
                                            color: '#fff',
                                            backgroundColor: '#d92923',
                                            borderColor: '#d92923',
                                        }}
                                    >
                                        Request Expedite
                                    </Button>

                                    <Button
                                        className="mr-2"
                                        id="action-button"
                                        type="primary"
                                        onClick={() => {
                                            setRequest_type_update({ type_notification: 'request_batch_date_change' });
                                            setSelectedDate([]);
                                            setShowModal(!showModal);
                                        }}
                                        style={{
                                            color: '#fff',
                                            backgroundColor: '#d92923',
                                            borderColor: '#d92923',
                                        }}
                                    >
                                        Request Batch Date Change
                                    </Button>
                                </div>
                            )}
                            <Button
                                className="mr-2"
                                id="action-button"
                                type="primary"
                                onClick={() => {
                                    setRequest_type_update({ type_notification: 'request_update' });
                                    setSelectedDate([]);
                                    setShowModal(!showModal);
                                }}
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#d92923',
                                    borderColor: '#d92923',
                                }}
                            >
                                Request Update
                            </Button>

                            <Button
                                className="mr-2"
                                id="action-button"
                                type="primary"
                                onClick={() => {
                                    setShowRequestOrderAdjustment(true);
                                }}
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#d92923',
                                    borderColor: '#d92923',
                                }}
                            >
                                Request Order Adjustment
                            </Button>
                            <Button
                                id="action-button"
                                icon={<DownloadOutlined />}
                                onClick={() => {
                                    downloadPdf(sales_order_id, SalesOrderStore?.order?.name);
                                }}
                                style={{
                                    color: '#d92923',
                                    borderColor: '#d92923',
                                    marginLeft: '10px',
                                }}
                            >
                                Reprint/Download Sales Order PDF
                            </Button>
                            {hasGroup('Production')
                            && !['Shipped in Full', 'Shipped & Paid', 'Completed'].includes(SalesOrderStore.order.status)
                            && (
                                <Button
                                    hidden={
                                        SalesOrderStore.order &&
                                        ['Shipped in Full', 'Shipped & Paid', 'Completed'].includes(
                                            SalesOrderStore.order.status
                                        )
                                    }
                                    className="ml-2"
                                    id="action-button"
                                    type="primary"
                                    onClick={() => {
                                        history.push(`/new-sales-order-detail/${sales_order_id}/cancellation`);
                                    }}
                                    style={{
                                        color: '#fff',
                                        backgroundColor: '#d92923',
                                        borderColor: '#d92923',
                                    }}
                                >
                                    Cancel Items
                                </Button>
                            )}
                        </div>
                    </div>
                    <br />

                    <div className="flex flex-row justify-between">
                        <Button
                            id="action-button"
                            icon={<MessageOutlined />}
                            onClick={() => {
                                setShowModalShipping(!showModalShipping);
                            }}
                            style={{
                                color: '#d92923',
                                borderColor: '#d92923',
                                marginLeft: 'auto',
                                marginRight: '0',
                            }}
                        >
                            Message to shipping_team
                        </Button>
                    </div>

                    <TotalUnreadMessagesAlert zoho_id={SalesOrderStore.order.zoho_id ?? '0'} />

                    <Divider className={'spacing'} />

                    <div
                        style={{
                            overflowY: 'auto',
                        }}
                        className="ml-5"
                    >
                        <div className={'header max-w-xl'}>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col">
                                    <p className="label flex items-center text-base m-0 font-serif">
                                        <NumberOutlined className="mr-1" /> PO Number:
                                    </p>
                                    <p className={'font-bold text-base ml-1'} style={{ width: '6vw' }}>
                                        {SalesOrderStore.order.purchase_order ?? '--'}
                                    </p>
                                </div>

                                <div className="flex flex-col justify-center ">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <CalendarOutlined className="mr-1" /> Estimated Ship Date:
                                    </p>
                                    <p className={'font-bold ml-1 text-base ml-1'}>
                                        {SalesOrderStore.order.required_ship_date ?? 'In Review'}
                                    </p>
                                </div>
                            </div>

                            <Divider className={'spacing'} />

                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col justify-center ">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <UserOutlined className="mr-1" /> Account:
                                    </p>
                                    <p className={'font-bold ml-1 text-base ml-1'}>
                                        {SalesOrderStore.order.account?.name ?? '-'}
                                    </p>
                                </div>
                                <div className="flex flex-col justify-center ">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <UserOutlined className="mr-1" /> Contact:
                                    </p>
                                    <p className={'font-bold ml-1 text-base ml-1'}>
                                        {SalesOrderStore.order.contact?.fullname ?? '-'}{' '}
                                        {SalesOrderStore.order.contact?.email ?? '-'}
                                    </p>
                                </div>
                            </div>

                            <Divider className={'spacing'} />

                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Col md={12}>
                                    <Form.Item
                                        label={'Shipping Address'}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        labelAlign={'left'}
                                    >
                                        {getFieldDecorator('shipping_address', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'This file is required',
                                                    validator: is_valid_address,
                                                },
                                            ],
                                            initialValue: {
                                                name: SalesOrderStore.order.shipping_name,
                                                street: SalesOrderStore.order.shipping_street,
                                                city: SalesOrderStore.order.shipping_city,
                                                state: SalesOrderStore.order.shipping_state,
                                                country: {
                                                    code: SalesOrderStore.order.shipping_country,
                                                    alpha3: SalesOrderStore.order.shipping_country,
                                                    name: SalesOrderStore.order.shipping_country,
                                                },
                                                zip_code: SalesOrderStore.order.shipping_zip_code,
                                            },
                                        })(
                                            <Addresses
                                                addresses={addresses}
                                                onAddNewAddress={() => setShowAddNewAddress(true)}
                                                onChange={(address: AddressProps) => {
                                                    const payload = {
                                                        shipping_name: address.name,
                                                        shipping_street: address.street,
                                                        shipping_city: address.city,
                                                        shipping_state: address.state,
                                                        shipping_zip_code: address.zip_code,
                                                        shipping_country: address.country?.code,
                                                    };
                                                    SalesOrderStore.update(parseInt(sales_order_id), payload);
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item
                                        label={'Billing Address'}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        labelAlign={'left'}
                                    >
                                        {getFieldDecorator('billing_address', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'This file is required',
                                                    validator: is_valid_address,
                                                },
                                            ],
                                            initialValue: {
                                                name: SalesOrderStore.order.billing_name,
                                                street: SalesOrderStore.order.billing_street,
                                                city: SalesOrderStore.order.billing_city,
                                                state: SalesOrderStore.order.billing_state,
                                                country: {
                                                    code: SalesOrderStore.order.billing_country,
                                                    alpha3: SalesOrderStore.order.billing_country,
                                                    name: SalesOrderStore.order.billing_country,
                                                },
                                                zip_code: SalesOrderStore.order.billing_zip_code,
                                            },
                                        })(
                                            <Addresses
                                                addresses={addresses}
                                                onAddNewAddress={() => setShowAddNewAddress(true)}
                                                onChange={(address: AddressProps) => {
                                                    const payload = {
                                                        billing_name: address.name,
                                                        billing_street: address.street,
                                                        billing_city: address.city,
                                                        billing_state: address.state,
                                                        billing_zip_code: address.zip_code,
                                                        billing_country: address.country?.code,
                                                    };
                                                    SalesOrderStore.update(parseInt(sales_order_id), payload);
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Modal
                                    visible={showAddNewAddress}
                                    onCancel={() => {
                                        setShowAddNewAddress(false);
                                    }}
                                    footer={[]}
                                >
                                    <AddressForm
                                        handleSave={payload => {
                                            SalesOrderStore.update(parseInt(sales_order_id), payload);
                                            return SalesOrderStore.saveAddress(
                                                SalesOrderStore?.order?.contact?.id,
                                                payload
                                            ).then(() => {
                                                SalesOrderStore.getContactById(SalesOrderStore?.order?.contact?.id);
                                            });
                                        }}
                                        handleClose={() => {
                                            setShowAddNewAddress(false);
                                        }}
                                    />
                                </Modal>
                            </div>
                            <Divider />
                            <Divider className={'spacing'} />
                        </div>

                        <div>
                            <p className={'label flex items-center font-bold font-serif text-base text-red-500'}>
                                <OrderedListOutlined className="mr-1" /> Line Items:
                            </p>
                        </div>
                        <Table
                            rowKey={record => record.id.toString()}
                            pagination={false}
                            // rowSelection={rowSelection}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            columns={filtered_columns}
                            dataSource={orderItems}
                            className="border"
                            footer={() => (
                                <Row style={{ marginTop: 15 }}>
                                    <Col sm={24} md={12} />
                                    <Col sm={24} md={12}>
                                        <div className={'flex flex-col justify-between'}>
                                            <div className="flex flex-row justify-between  mb-1">
                                                <div className="summary-label">Subtotal</div>
                                                <div className="summary-value">
                                                    <Price value={SalesOrderStore.order?.subtotal ?? 0} />
                                                </div>
                                            </div>

                                            <div className="flex flex-row justify-between mt-1">
                                                <div className="summary-label">Discount</div>
                                                <div className="summary-value">
                                                    <Price value={SalesOrderStore.order?.discount ?? 0} />
                                                </div>
                                            </div>

                                            <div className="flex flex-row justify-between  mt-2">
                                                <div className="summary-label">Tax</div>
                                                <div className="summary-value">
                                                    <Price value={SalesOrderStore.order?.tax ?? 0} />
                                                </div>
                                            </div>

                                            <div
                                                className="flex flex-row justify-between  mt-2 pt-4"
                                                style={{
                                                    borderTop: 'solid 1px #d9d9d9',
                                                }}
                                            >
                                                <div className="font-bold text-xl">Grand Total</div>
                                                <div className="font-bold text-xl">
                                                    <Price value={SalesOrderStore.order?.grand_total ?? 0} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
                        />
                    </div>
                    <Tabs defaultActiveKey="1" className="mt-10">
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.notes.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <ReadOutlined className="mr-2" />
                                    </Badge>
                                    Notes
                                </span>
                            }
                            key="1"
                        >
                            <List
                                size="small"
                                bordered
                                loading={loading}
                                dataSource={SalesOrderStore.notes}
                                renderItem={item => <List.Item>{item.content}</List.Item>}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.order.additional_requirements?.length ?? 0}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <OrderedListOutlined className="mr-2" />
                                    </Badge>
                                    Additional Requirements
                                </span>
                            }
                            key="2"
                        >
                            <List
                                size="small"
                                bordered
                                loading={loading}
                                dataSource={SalesOrderStore.order.additional_requirements ?? []}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.shipments.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <OrderedListOutlined className="mr-2" />
                                    </Badge>
                                    View Shipments to the Customer
                                </span>
                            }
                            key="3"
                        >
                            {SalesOrderStore.order && <Shipments order={SalesOrderStore.order} />}
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={
                                            SalesOrderStore.quality_documents.filter(item => item.documents.length > 0)
                                                .length
                                        }
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <OrderedListOutlined className="mr-2" />
                                    </Badge>
                                    Quality Documents
                                </span>
                            }
                            key="4"
                        >
                            {loadingProductionInformation && (
                                <div className="w-full m-4 py-8 text-center">
                                    <Skeleton paragraph={{ rows: 4 }} />
                                </div>
                            )}
                            <QualityDocs />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.invoices.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <WalletOutlined className="mr-2" />
                                    </Badge>
                                    Invoices
                                </span>
                            }
                            key="5"
                        >
                            <Invoices />
                        </TabPane>

                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.notifications.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <ReadOutlined className="mr-2" />
                                    </Badge>
                                    Messages
                                </span>
                            }
                            key="6"
                        >
                            <Chat
                                height={450}
                                messages={SalesOrderStore.notifications}
                                onSendMessage={(payload: any) => {
                                    const formData = new FormData();

                                    if (payload.body) {
                                        formData.append('body', payload.body);
                                    }

                                    payload.attachments.forEach((item: any) => {
                                        formData.append('attachments_files', item);
                                    });

                                    const url = `staff/sales-orders/${sales_order_id}/notifications/`;

                                    post(url, formData)
                                        .then((response: any) => {
                                            SalesOrderStore.getNotifications(sales_order_id);
                                        })
                                        .catch((error: any) => notification.error(error));
                                }}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        style={{ position: 'absolute', right: '20px' }}
                                        count={TicketStore.totalUnreadMessages}
                                    >
                                        <MessageOutlined className="mr-2" />
                                    </Badge>
                                    Ticket Support
                                </span>
                            }
                            key="7"
                        >
                            <TicketMainPage />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        style={{ position: 'absolute', right: '20px' }}
                                        count={totalUnreadMessagesSalesRequest}
                                    >
                                        <MessageOutlined className="mr-2" />
                                    </Badge>
                                    Sales Request
                                </span>
                            }
                            key="8"
                        >
                            <TicketMainPage message_type="production-sales" />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge style={{ position: 'absolute', right: '20px' }} count={totalCancelledItems}>
                                        <MinusCircleOutlined className="mr-2" />
                                    </Badge>
                                    Cancellation History
                                </span>
                            }
                            key="9"
                        >
                            <CancelItemsHistory sales_order_id={sales_order_id} />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <IssuesCloseOutlined className="mr-2" />
                                    Quality Order Submissions
                                </span>
                            }
                            key="10"
                        >
                            <QualityIssuesList sales_order_id={sales_order_id} />
                        </TabPane>
                    </Tabs>
                </>
            )}

            {showProductFeedbackModal && (
                <>
                    <Modal
                        visible={showProductFeedbackModal}
                        closable={true}
                        title={'Product Feedback'}
                        onCancel={() => setShowProductFeedbackModal(false)}
                        destroyOnClose={true}
                        footer={[
                            <Button
                                loading={loadingButton}
                                key="button-request-update"
                                type="primary"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#d92923',
                                    borderColor: '#d92923',
                                    marginLeft: 'auto',
                                }}
                                onClick={async () => {
                                    if (itemForFeedback) {
                                        saveProductFeedback(itemForFeedback.id);
                                        setShowProductFeedbackModal(false);
                                    }
                                }}
                            >
                                Save Feedback
                            </Button>,
                        ]}
                    >
                        <TextArea
                            value={currentFeedback}
                            onChange={e => {
                                setCurrentFeedback(e.target.value);
                            }}
                        ></TextArea>
                    </Modal>
                </>
            )}

            {showModal && (
                <>
                    <Modal
                        closable={true}
                        title={
                            <span className="flex items-center font-bold">
                                {request_type_update?.type_notification == 'request_update' && (
                                    <PullRequestOutlined className="mr-1" />
                                )}
                                {request_type_update?.type_notification == 'request_expedite' && (
                                    <ThunderboltFilled className="mr-1" />
                                )}
                                {request_type_update?.type_notification == 'request_batch_date_change' && (
                                    <ScheduleOutlined className="mr-1" />
                                )}
                                {request_type_update && capitalizeWords(request_type_update.type_notification)}
                            </span>
                        }
                        visible={showModal}
                        onCancel={() => setShowModal(false)}
                        destroyOnClose={true}
                        footer={[
                            <Button
                                loading={loadingButton}
                                key="button-request-update"
                                type="primary"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#d92923',
                                    borderColor: '#d92923',
                                    marginLeft: 'auto',
                                }}
                                onClick={async () => {
                                    setLoadingButton(true);
                                    request_type_update && (await onSubmit(request_type_update.type_notification));
                                    setSelectedDate([]);
                                    setShowModal(false);
                                    setNotes('');
                                    setLoadingButton(false);
                                }}
                            >
                                Send Request
                            </Button>,
                        ]}
                    >
                        {request_type_update?.type_notification == 'request_update' && selectLineItems.length > 0 && (
                            <>
                                <table>
                                    <tr>
                                        <th>Id</th>
                                        <th>
                                            <p className="font-bold">Line Items Selected</p>
                                        </th>
                                    </tr>
                                    {selectLineItems.map((lines: IOrderLineItemProps) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td style={{ textAlign: 'left' }}>
                                                        <span>{lines.name}</span>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </table>
                                <br />
                            </>
                        )}
                        {request_type_update?.type_notification == 'request_expedite' && (
                            <>
                                <table>
                                    <tr>
                                        <th>
                                            <p className="font-bold">Line Items Selected</p>
                                        </th>
                                        <th>
                                            <p className="font-bold">Current Ship date</p>
                                        </th>
                                        <th>
                                            <p className="font-bold">Target Date</p>
                                        </th>
                                    </tr>
                                    {selectLineItems &&
                                        selectLineItems.map((lines: IOrderLineItemProps) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <span>{lines.name}</span>
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <span>{lines?.required_part_ship_date} </span>
                                                        </td>
                                                        <td>
                                                            <DatePicker
                                                                disabledDate={current => {
                                                                    return current && current < moment().add(-1, 'day');
                                                                }}
                                                                onChange={(date: any | null) => {
                                                                    const payload = {
                                                                        id: lines.id,
                                                                        name: lines.name,
                                                                        date_target: dayjs(date).format('M/D/YYYY'),
                                                                    };
                                                                    setSelectedDate([...selectedDate, payload]);
                                                                }}
                                                                size="small"
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </table>
                                <br />
                            </>
                        )}
                        {request_type_update?.type_notification == 'request_batch_date_change' && (
                            <>
                                <table>
                                    <tr>
                                        <th>
                                            <p className="font-bold">Line Items Selected</p>
                                        </th>
                                        <th>
                                            <p className="font-bold">Current Ship date</p>
                                        </th>
                                        <th>
                                            <p className="font-bold">Target Date</p>
                                        </th>
                                    </tr>
                                    {selectLineItems &&
                                        selectLineItems.map((lines: IOrderLineItemProps) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <span>{lines.name}</span>
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <span>{lines?.required_part_ship_date} </span>
                                                        </td>
                                                        <td>
                                                            <DatePicker
                                                                disabledDate={current => {
                                                                    return current && current < moment().add(-1, 'day');
                                                                }}
                                                                onChange={(date: any | null) => {
                                                                    const payload = {
                                                                        id: lines.id,
                                                                        name: lines.name,
                                                                        date_target: dayjs(date).format('M/D/YYYY'),
                                                                    };
                                                                    setSelectedDate([...selectedDate, payload]);
                                                                }}
                                                                size="small"
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </table>
                                <br />
                            </>
                        )}

                        <p className="font-bold">Add any additional notes:</p>
                        <Input.TextArea
                            value={notes}
                            onChange={e => {
                                setNotes(e.target.value);
                            }}
                        />
                    </Modal>
                </>
            )}
            {showModalShipping && (
                <>
                    <Modal
                        closable={true}
                        title={'Send Rocket To Shipping Team'}
                        visible={showModalShipping}
                        onCancel={() => {
                            setShowModalShipping(false);
                            settypeShipment('');
                        }}
                        destroyOnClose={true}
                        footer={[]}
                    >
                        <>
                            <Select
                                defaultValue=""
                                style={{ width: 400 }}
                                onChange={e => {
                                    settypeShipment(e);
                                }}
                                options={[
                                    {
                                        value: 'Provide Feedback on a Shipment',
                                        label: 'Provide Feedback on a Shipment',
                                    },
                                    {
                                        value: 'Request Assistance',
                                        label: 'Request Assistance',
                                    },
                                    {
                                        value: 'Request Expedite',
                                        label: 'Request Expedite',
                                    },
                                    {
                                        value: 'Notify of Change',
                                        label: 'Notify of Change',
                                    },
                                ]}
                            />
                            {typeShipment && (
                                <>
                                    <Divider className={'spacing'} />
                                    <Input.TextArea
                                        value={notesShipment}
                                        onChange={e => {
                                            setNotesShipment(e.target.value);
                                        }}
                                    />

                                    {typeShipment == 'Provide Feedback on a Shipment' && (
                                        <>
                                            <br />
                                            <br />
                                            <Upload {...UploadProps}>
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <Button
                                        loading={loadingButton}
                                        key="button-request-update"
                                        type="primary"
                                        style={{
                                            color: '#fff',
                                            backgroundColor: '#d92923',
                                            borderColor: '#d92923',
                                            marginLeft: 'auto',
                                        }}
                                        onClick={async () => {
                                            setLoadingButton(true);
                                            SalesOrderStore?.order && send_to_shipping_team();
                                            SalesOrderStore?.order?.id && setShowModalShipping(false);
                                            setLoadingButton(false);
                                        }}
                                    >
                                        Send to Rocket
                                    </Button>
                                </>
                            )}
                        </>
                    </Modal>
                </>
            )}

            {showModalNotesByItem && notesByItem && (
                <Modal
                    closable={true}
                    title={
                        <span className="flex items-center font-bold">
                            <PullRequestOutlined className="mr-1" />
                            Notes for Item: {notesByItem.item}
                        </span>
                    }
                    visible={showModalNotesByItem}
                    onCancel={() => setShowModalNotesByItem(false)}
                    destroyOnClose={true}
                    width={800}
                    footer={[
                        <Button
                            key="button-request-update"
                            type="default"
                            onClick={() => {
                                setShowModalNotesByItem(false);
                            }}
                        >
                            Close
                        </Button>,
                    ]}
                >
                    <NotesForm
                        handleSubmit={(data: NoteCreateProps) => {
                            data.id = notesByItem.id;
                            SalesOrderStore.createNoteByItem(sales_order_id, data)
                                .then(async () => {
                                    notification.success({ message: 'New note added' });
                                    setShowModalNotesByItem(false);
                                    await SalesOrderStore.getById(sales_order_id);
                                    await getVendorInformation(sales_order_id);
                                })
                                .catch((err: any) => {
                                    notification.error({
                                        message: <ErrorRender error={err} />,
                                        placement: 'topRight',
                                    });
                                });
                        }}
                        form={props.form}
                    />
                    {notesByItem.notes.length > 0 && <NotesListCard sales_order_notes={notesByItem.notes} />}
                </Modal>
            )}

            {showRequestOrderAdjustment && order?.id && (
                <RequestOrderAdjustment
                    onClose={() => setShowRequestOrderAdjustment(false)}
                    order={order}
                    showRequestOrderAdjustment={showRequestOrderAdjustment}
                />
            )}
        </Card>
    );
});

export default Form.create()(Details);
