import React from 'react';
import { Button, Checkbox, InputNumber, Popconfirm, Table } from 'antd';
import { IPartQualityItem } from '@partsbadger/types';

type Item = {
    id: number;
    name: string;
    reason?: string[];
    quantity: number;
    quantity_ordered: number;
    invoice_status: string | null;
    credit_memo_needed: boolean;
};

type Props = {
    handleUpdateItem: (id: number, key: string, value: any) => void;
    handleRemove: (id: number) => void;
    items: Item[];
    loading: boolean;
};

export const ReworkTable = (props: Props) => {
    const columns = [
        {
            title: 'Parts Name',
            key: 'parts_name',
            align: 'center',
            render: (r: Item) => <>{r.name}</>,
        },
        ...(props.items[0]?.reason
            ? [
                  {
                      title: 'Reason',
                      key: 'reason',
                      align: 'center',
                      render: (r: Item) => <>{r.reason?.join(', ')}</>,
                  },
              ]
            : []),
        {
            title: 'Quantity Rework',
            key: 'quantity_rework',
            align: 'center',
            render: (r: Item) => (
                <div className={'flex flex-column items-center'}>
                    <InputNumber
                        min={1}
                        max={r.quantity_ordered}
                        value={r.quantity}
                        onChange={value => props.handleUpdateItem(r.id, 'quantity', value)}
                    />
                    <span className={'text-gray-500'}>Qty Ordered: {r.quantity_ordered}</span>
                </div>
            ),
        },
        {
            title: 'Paid',
            key: 'paid',
            align: 'center',
            render: (r: Item) => <>{r.invoice_status === 'Paid' ? 'Yes' : 'No'}</>,
        },
        {
            title: 'Credit Memo Needed?',
            key: 'fault_of',
            align: 'center',
            render: (r: Item) => (
                <Checkbox
                    checked={r.credit_memo_needed}
                    onChange={e => props.handleUpdateItem(r.id, 'credit_memo_needed', e.target.checked)}
                />
            ),
        },
        {
            title: 'Actions',
            key: 'remove',
            render: (r: Item) => (
                <Button size="small" type="link" onClick={() => props.handleRemove(r.id)}>
                    Remove
                </Button>
            ),
        },
    ];

    return (
        <Table
            //@ts-ignore
            columns={columns}
            dataSource={props.items}
            loading={props.loading}
            pagination={false}
        />
    );
};
